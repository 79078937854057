// LastEpisode.js

import React from 'react';
import './LastEpisode.css';
import download from '../../assets/download.png';
import external from '../../assets/external.png';
const NowPlaying = React.lazy(() => import('../NowPlaying/NowPlaying'));

function MostRecent() {

    /* const audioSrc = "http://35.7.34.15:5150/(0) Last Episode/LastEpisode.mp3" */
    const audioSrc = "https://djdoubletwo.duckdns.org:5150/(0) Last Episode/LastEpisode.mp3"
    const driveLink = "https://drive.google.com/uc?export=download&id=1BkP-yE5TKwg0tQV8lzBbJsLZUAKVIzGN"
    const epName = "10/11/24 Going Back To Cali"
    const spinLink = "https://spinitron.com/WCBN/pl/19640577/Live-from-the-Dungeon"
    const lastDescription = "This week's broadcast features only California MC's. Enjoy."

    return (
        <div className='lastEpisode'>
            
            <h1>Last Episode<hr className='headerHR'/></h1>
            
            <div className='recentContent'>

                <h2 className='lastLink'><a href={driveLink}>{epName}: <img src={ download } className='downloadIcon' alt='Download Icon' width="25px"/></a></h2>
                
                <div className='lastDescription'>{lastDescription}</div>
               
                <NowPlaying name={epName} linkSrc={audioSrc} />

                <hr/>
                
                <div className='tracks'>
                    <h2 className='lastLink'><a href={spinLink} target='_blank' rel='noreferrer'>Playlist:  <img src={ external } className='externalIcon' alt='External Icon' width="20px"/></a></h2>
                    <div className='playlist'>
                        <li className='MRLi'>1. 2Pac / Picture Me Rollin' (feat. Danny Boy, Syke & CPO) / Greatest Hits / 2Pac Greatest Hits / 1998</li>
                        <li className='MRLi'>2. Ice Cube / You Know How We Do It / Lethal Injection / Priority Records / 1993</li>
                        <li className='MRLi'>3. Skee-Lo / I Wish / I Wish / Legacy Recordings / 1995</li>
                        <li className='MRLi'>4. Ahmad / Back in the Day / Ahmad / Giant / 1994</li>
                        <li className='MRLi'>5. The Pharcyde / Passin' Me By / Bizarre Ride II the Pharcyde / The Bicycle Music Company / 1992</li>
                        <li className='MRLi'>6. People Under the Stairs / The L.A. Song / O.S.T. / Om / 2003</li>
                        <li className='MRLi'>7. Hieroglyphics / You Never Knew / 3rd Eye Vision / Hieroglyphics Imperium / 1998</li>
                        <li className='MRLi'>8. Dilated Peoples / Pay Attention / Expansion Team / Capitol Records / 2001</li>
                        <li className='MRLi'>9. Cypress Hill / Boom Biddy Bye Bye / III (Temples Of Boom) / Ruffhouse/Columbia / 1995</li>
                        <li className='MRLi'>10. Paris / Guerrilla Funk (Deep Fo' Real Mix) / Guerrilla Funk (The Deluxe Edition) [Re-mastered,Bonus Tracks] / Guerrilla Funk Recordings / 2003</li>
                        <li className='MRLi'>11. Sam Sneed / U Better Recognize (feat. Dr. Dre) / Street Scholars / Death Row Records/gamma. / 2011</li>
                        <li className='MRLi'>12. Snoop Dogg / Gin and Juice / Doggystyle / Death Row Records/gamma. / 1993</li>
                        <li className='MRLi'>13. 2Pac / I Get Around / Greatest Hits / 2Pac Greatest Hits / 1998</li>
                    </div>
                </div>
            </div>

            <hr/>

        </div>
    );
};

export default MostRecent;